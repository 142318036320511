import React from 'react';

import './PodcastLinks.css';
import imageAnchor from '../images/platform-anchor@2x.png';
import imageApple from '../images/platform-apple_podcasts@2x.png';
import imageBreaker from '../images/platform-breaker@2x.png';
import imageCastbox from '../images/platform-castbox@2x.png';
import imageGoogle from '../images/platform-google_podcasts_outline@2x.png';
import imageOvercast from '../images/platform-overcast@2x.png';
import imagePocketCast from '../images/platform-pocket_casts@2x.png';
import imageRadioPublic from '../images/platform-radiopublic@2x.png';
import imageSpotify from '../images/platform-spotify@2x.png';
import imagePodBean from '../images/platform-podbean.png';

export default function PodcastLinks() {
  return (
    <div>
      <strong>This podcast is available&nbsp;on...</strong>
      <br />
      <br />
      <div className="links">
        <div className="link">
          <img src={imageAnchor} alt="podcatchet logo" />
          <a
            href="https://anch.co/get_anchor"
            target="_blank"
            rel="noopener noreferrer"
          >
            Anchor
          </a>
        </div>

        <div className="link">
          <img src={imageApple} alt="podcatchet logo" />
          <a
            href="https://itunes.apple.com/us/podcast/remote-guys-night/id1372509464"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple Podcasts
          </a>
        </div>

        <div className="link">
          <img src={imageBreaker} alt="podcatchet logo" />
          <a
            href="https://www.breaker.audio/remote-guys-night"
            target="_blank"
            rel="noopener noreferrer"
          >
            Breaker
          </a>
        </div>

        <div className="link">
          <img src={imageCastbox} alt="podcatchet logo" />
          <a
            href="https://castbox.fm/channel/id1239342"
            target="_blank"
            rel="noopener noreferrer"
          >
            Castbox
          </a>
        </div>

        <div className="link">
          <img src={imageGoogle} alt="podcatchet logo" />
          <a
            href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8zNTVmZWE4L3BvZGNhc3QvcnNz"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Podcasts
          </a>
        </div>

        <div className="link">
          <img src={imageOvercast} alt="podcatchet logo" />
          <a
            href="https://overcast.fm/itunes1372509464/remote-guys-night"
            target="_blank"
            rel="noopener noreferrer"
          >
            Overcast
          </a>
        </div>

        <div className="link">
          <img src={imagePocketCast} alt="podcatchet logo" />
          <a
            href="http://pca.st/02xy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pocket Casts
          </a>
        </div>

        <div className="link">
          <img src={imagePodBean} alt="podbean logo" />
          <a
            href="https://www.podbean.com/podcast-detail/8nd39-8011f/Remote-Guys-Night-Podcast"
            target="_blank"
            rel="noopener noreferrer"
          >
            PodBean
          </a>
        </div>

        <div className="link">
          <img src={imageRadioPublic} alt="podcatchet logo" />
          <a
            href="https://play.radiopublic.com/remote-guys-night-WwDbbP"
            target="_blank"
            rel="noopener noreferrer"
          >
            RadioPublic
          </a>
        </div>

        <div className="link">
          <img src={imageSpotify} alt="podcatchet logo" />
          <a
            href="https://open.spotify.com/show/5E2vzheUk561qfjEG0hmnV"
            target="_blank"
            rel="noopener noreferrer"
          >
            Spotify
          </a>
        </div>
      </div>
    </div>
  );
}
