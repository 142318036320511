import React from 'react';

import PodcastPlayer from './PodcastPlayer';
import PodcastLinks from './PodcastLinks';
// import List from './components/List';
// import entries from './entries.js';

export default function HomePage() {
  return (
    <div>
      <main>
        <section>
          <h1>
            Remote
            <br />
            Guys Night
          </h1>
        </section>
        <section>
          <PodcastLinks />
        </section>
        <section>
          <PodcastPlayer />
        </section>
        <section>
          <h2>Contact Us</h2>
          <p>
            <a href="mailto:podcast@remoteguysnight.com">
              <i className="fa fa-envelope" /> podcast@remoteguysnight.com
            </a>
            <br />
            <br />
            <a href="https://twitter.com/remoteguysnight">
              <i className="fab fa-twitter" /> @remoteguysnight
            </a>
          </p>
        </section>
        {/* <section>
            <List
              title="WANT TO WORK REMOTE?"
              subtitle="These companies are hiring for remote positions:"
              entries={entries}
            />
          </section> */}
      </main>
    </div>
  );
}
