import React from "react";

import "./PodcastPlayer.css";

export default function PodcastPlayer() {
  return (
    <div className="podcast-player">
      <h2>LISTEN TO OUR PODCAST</h2>
      <iframe
        title="Podcast Player"
        src="https://anchor.fm/remote-guys-night/embed/episodes/Kyle-Purdy-ecdr86/a-a1s0tll"
        height="102px"
        width="290px"
        frameBorder="0"
        scrolling="no"
      />
      <br />
      <br />
      <a
        className="button"
        target="_blank"
        href="https://anchor.fm/remote-guys-night"
        rel="noopener noreferrer"
      >
        More episodes
      </a>
      <p>
        Welcome to remote guys night the show where brothers distributed across
        the world come together over the internet to drink beers, tell stories,
        and exchange&nbsp;jokes.
      </p>
    </div>
  );
}
